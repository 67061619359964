@font-face {
  font-family: 'Lufga';
  src: local('Lufga'),
       url('/src/fonts/LufgaRegular.ttf') format('truetype');
  font-weight: normal;
  font-style: 'normal';
}
body {
  font-family: 'Lufga !important';
}

