.App {
  text-align: center;
}
body{
  margin:0px !important;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}


.swiper-pagination-bullet {
  background: #fff !important;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

h1 {
  font-family: "Lufga";
  font-weight: bold;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-input {
  color: #ed1d24; /* Red text color */
  background: #fff0f0; /* Light red background */
  border-radius: 0.75rem;
}

/* Target the placeholder text color when there's an error */
.MuiOutlinedInput-root.Mui-error .MuiInputBase-input::placeholder {
  color: #ed1d24; /* Red placeholder color */
  opacity: 1; /* Ensure the placeholder is fully opaque */
}

/* Target the border color when there's an error */
.MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline {
  border-color: #ed1d24; /* Red border color */
}

/* Target the background and text color for the input field when there's an error */
.MuiOutlinedInput-root.Mui-error {
  background-color: #fff0f0; /* Light red background */
}

/* Ensure the SEND OTP button remains unaffected by the error */
.MuiOutlinedInput-root.Mui-error .MuiInputAdornment-root button {
  color: #00AB59; /* Keep the button color as green */
}

/* Optional: Customize the SEND OTP button background */
.MuiOutlinedInput-root.Mui-error .MuiInputAdornment-root button:hover {
  background-color: #e0f2f1; /* Slightly lighter green background on hover */
}
